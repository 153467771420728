body {
  font-family: "Montserrat", sans-serif !important;
  line-height: 1.8 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 53px;
  font-weight: 700;
}


.slick-prev {
  /* left:-12% ; */
  z-index: 1;
}
.slick-next {
  /* right: -12% !important; */
  z-index: 1;
}

.slick-arrow.slick-next,
.slick-arrow.slick-prev{
/* background-color: rgb(26, 21, 21); */
height:25px;
width: 25px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
opacity: 0.3;
height:50px;
width:50px;
}

.slick-arrow.slick-next:hover,
.slick-arrow.slick-prev:hover
{
    opacity:1;
    transition: opacity 0.3s;
}

.slick-prev::before,.slick-next::before{
   display:none;
    }